@import '../../../../styles/customMediaQueries.css';
.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 100vh;
  @media (--viewportMedium) {
    min-height: 90vh;
  }
  display: flex;
  justify-content: center;
  align-items: center;
}
